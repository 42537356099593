import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout/Layout'
import Blocks from '@/lib/blocks/Blocks'
import MetaInfo from '@/layout/MetaInfo'

const Page = ({ data: { pg } }) => (
	<Layout>
		<Blocks blocks={pg.blocks} />
	</Layout>
)

export default Page

export const Head = MetaInfo

export const query = graphql`query ($id: String) {
	pg: sanityPage(id: {eq: $id}) {
		blocks {
			...ambassadorList
			...bePartnerSection
			...blogCarousel
			...columnList
			...doubleDonation
			...employeeList
			...eventCalendar
			...eventList
			...fileList
			...footerCtaSection
			...heroImage
			...heroMain
			...heroVideo
			...imageTwoColumn
			...logoGrid
			...mentorshipSection
			...newsList
			...partnerList
			...programListStatic
			...richtextSection
			...signupForm
			...signUpFormCustom
			...simpleCarousel
			...studentList
			...tabbedCardList
			...testimonialSection
			...textCtaSection
			...textMediaSection
			...textSection
			...textTwoColumn
		}
		metaInfo { ...metaInfo }
	}
}`
